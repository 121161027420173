<app-main-nav>
  <div id="main">
    <div id="sideBox">
      <div id="paddedContainer" class="jaguarFont">
        <div id="driverNumberRow">
          <div id="driverNumberContainer">
            <mat-icon
              (click)="goBack()"
              (auxclick)="goBackNewTab($event)"
              id="backButton"
            >
              undo
            </mat-icon>
            <div [style.display]="trueDriver ? 'none' : 'initial'">
              {{ selectedDriver }} - {{ selectedDriverName }} - P{{
                standings.driverToPos[selectedDriver]
              }}
            </div>
            <div [style.display]="trueDriver ? 'initial' : 'none'">
              {{ selectedDriver }} - {{ selectedDriverName }}
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div id="lapOffsetRow" class="jaguarFont driverControlsRow">
          <div id="offsetGrid">
            <div id="lapOffsetColumn">
              <h3>Lap Offset</h3>
              <div id="lapOffsetContainer">
                <mat-form-field id="lapOffsetForm" appearance="none">
                  <input
                    class="jaguarFont"
                    id="lapOffsetInput"
                    matInput
                    [(ngModel)]="lapOffset"
                    (keydown.enter)="updateLapOffset()"
                    (blur)="updateLapOffset()"
                  />
                </mat-form-field>
                <div id="lapOffsetIncrementers">
                  <button
                    mat-flat-button
                    (click)="increaseLapOffset()"
                    class="incrementButton"
                  >
                    <mat-icon> keyboard_arrow_up</mat-icon>
                  </button>
                  <button
                    mat-flat-button
                    (click)="decreaseLapOffset()"
                    class="incrementButton"
                  >
                    <mat-icon> keyboard_arrow_down</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div id="offsetDivider">
              <mat-divider vertical style="height: 100%"></mat-divider>
            </div>
            <div id="energyOffsetColumn">
              <div id="enoTitleRow">
                <h3>ENO</h3>
                <mat-icon
                  matTooltip="Energy target offset in kWh per lap. Does not affect displayed target energy."
                  [matTooltipPosition]="'above'"
                  >info</mat-icon
                >
              </div>
              <div class="energySlider">
                <div id="offsetDisplay" [ngStyle]="{ color: getOffsetColor() }">
                  <mat-form-field appearance="none">
                    <input
                      class="jaguarFont"
                      id="enoInput"
                      matInput
                      [(ngModel)]="energyOffsetString"
                      (keydown.enter)="updateOffset(true)"
                      (blur)="updateOffset(true)"
                    />
                  </mat-form-field>
                </div>
                <div id="energyOffsetIncrementers">
                  <button
                    mat-flat-button
                    (click)="increaseEnergyOffset()"
                    class="incrementButton"
                  >
                    <mat-icon> keyboard_arrow_up</mat-icon>
                  </button>
                  <button
                    mat-flat-button
                    (click)="decreaseEnergyOffset()"
                    class="incrementButton"
                  >
                    <mat-icon> keyboard_arrow_down</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div *ngIf="status.status.pitStop">
          <div class="centeredRow">
            <div class="pitInToggleContainer">
              <h5 class="pitText">Boost Charge</h5>
              <mat-slide-toggle
                [disabled]="hasCharged || pitting"
                [(ngModel)]="pitIn"
                (change)="setPitIn()"
              ></mat-slide-toggle>
            </div>
          </div>
          <div class="centeredRow teammateIndicator">
            <div
              [ngStyle]="{
                color:
                  teammate && energies.attacks[teammate].pitting
                    ? 'crimson'
                    : 'green'
              }"
            >
              Teammate Status:
              {{
                teammate && energies.attacks[teammate].pitting ? "PIT" : "TRACK"
              }}
            </div>
          </div>

          <mat-divider></mat-divider>
        </div>
        <div class="jaguarFont driverControlsRow">
          <ng-template [ngIf]="!attackModeOn">
            <h3>Attack Mode</h3>
          </ng-template>
          <ng-template [ngIf]="attackModeOn">
            <h3 id="attackModeOnText">Attack Mode On</h3>
          </ng-template>

          <div id="attackModeContainer">
            <div
              [ngStyle]="{ visibility: attackModeOn ? 'visible' : 'hidden' }"
              id="attackTimer"
            >
              {{ attackTimer }}
            </div>
            <mat-radio-group
              id="attackModeRadioGroup"
              [(ngModel)]="attackModeMode"
              (change)="changeAttackMode()"
              [disabled]="attacksDone > 0 || attackModeOn"
              [ngStyle]="{ opacity: attackModeOn ? '0.2' : '1' }"
            >
              <mat-radio-button
                class="attackModeRadioButton"
                *ngFor="let mode of possibleAttackModes"
                [value]="mode"
              >
                <div class="radioButtonLabel jaguarFont">
                  {{ mode }}
                </div>
              </mat-radio-button>
            </mat-radio-group>

            <div
              id="attacksDone"
              [ngStyle]="{ opacity: attackModeOn ? '0.2' : '1' }"
            >
              <h5>Attacks Done</h5>
              {{ attacksDoneString }}
            </div>

            <mat-divider vertical style="height: 120%"></mat-divider>
            <div
              id="allAttackModeToggles"
              [ngStyle]="{ opacity: attackModeOn ? '0.2' : '1' }"
            >
              <div class="attackModeToggleContainer">
                <h5 class="attackText">Attack This Lap</h5>
                <mat-slide-toggle
                  [disabled]="attacksDone > 1 || attackModeOn"
                  [(ngModel)]="attackModeSet"
                  (change)="setAttackMode()"
                ></mat-slide-toggle>
              </div>

              <div
                class="attackModeToggleContainer specialAttackModeToggle"
                [ngStyle]="{ opacity: attackModeOn ? '0.2' : '1' }"
              >
                <h5 class="attackText">Follow Attack</h5>
                <mat-slide-toggle
                  [disabled]="
                    attacksDone > 1 ||
                    attackModeOn ||
                    standings.driverToPos[selectedDriver] == 1
                  "
                  [(ngModel)]="followAttack"
                  (change)="setFollowAttack()"
                ></mat-slide-toggle>
              </div>
              <div
                class="attackModeToggleContainer specialAttackModeToggle"
                [ngStyle]="{ opacity: attackModeOn ? '0.2' : '1' }"
              >
                <h5 class="attackText">Attack Opposite</h5>
                <mat-slide-toggle
                  [disabled]="
                    attacksDone > 1 ||
                    attackModeOn ||
                    standings.driverToPos[selectedDriver] == 1
                  "
                  [(ngModel)]="attackOpposite"
                  (change)="setAttackOpposite()"
                ></mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>
        <div class="jaguarFont driverControlsRow" id="liftOffRow">
          <div id="liftOffTitle">
            <h3 class="stage-1-width-text liftOffLongTitle">
              Lift-Off Point For Next Overtaking Zone
            </h3>
            <h3 class="liftOffShortTitle">Lift-Off Point</h3>
            <button
              mat-raised-button
              (click)="resetNextOTSegmentAEC()"
              class="jaguarFont"
            >
              Reset
            </button>
          </div>

          <div id="liftOffSliderRow">
            <div id="ecoText" class="liftOffSliderElement">SAVE</div>
            <div
              id="{{
                !goingForOvertake
                  ? 'no-odds'
                  : overtakingProbability < 0.2
                  ? 'low-odds'
                  : overtakingProbability > 0.7
                  ? 'high-odds'
                  : 'med-odds'
              }}"
              class="liftOffSliderElement"
            >
              <mat-slider
                step="0.01"
                [min]="fullEcoAEC"
                [max]="flatOutAEC"
                [(ngModel)]="nextOTSegmentAEC"
                (change)="setNextOTSegmentAEC()"
                [disabled]="crossedOT"
                style="width: 100%"
                matTooltip="Sets lift off point for upcoming overtaking point, independent of target or ENO. Can be used to consume more energy to attempt overtake or save extra energy in that specific corner."
                matTooltipPosition="above"
              ></mat-slider>
            </div>
            <div id="flatOutText" class="liftOffSliderElement">
              <div>FLAT</div>
              <div>OUT</div>
            </div>
          </div>
          <div
            id="overtakingProbabilityRow"
            [style.display]="
              !overtaking && !beingOvertaken && !pitting ? 'block' : 'none'
            "
          >
            <ng-template
              [ngIf]="overtakingProbability <= 0.2 && goingForOvertake"
              class="overtakingProbabilityText"
            >
              <div style="color: rgb(255, 90, 90)" class="stage-1-width-text">
                Low Overtaking Probability
              </div>
            </ng-template>
            <ng-template
              [ngIf]="
                overtakingProbability > 0.2 &&
                overtakingProbability < 0.7 &&
                goingForOvertake
              "
              class="overtakingProbabilityText"
            >
              <div style="color: yellow" class="stage-1-width-text">
                Medium Overtaking Probability
              </div>
            </ng-template>
            <ng-template
              [ngIf]="overtakingProbability > 0.7 && goingForOvertake"
              class="overtakingProbabilityText"
            >
              <div style="color: chartreuse" class="stage-1-width-text">
                High Overtaking Probability
              </div>
            </ng-template>
            <ng-template [ngIf]="!goingForOvertake"
              ><div style="visibility: hidden" class="stage-1-width-text">
                .
              </div></ng-template
            >
          </div>

          <div
            id="overtakingRangeTexts"
            [style.display]="!overtaking && !beingOvertaken ? 'flex' : 'none'"
            class="stage-1-width-text"
          >
            <div
              id="overtakerRangeText"
              [style.color]="
                inRangeBehind ? 'rgb(255,255,255)' : 'rgb(70,70,70)'
              "
              [style.animation]="
                inRangeBehind ? 'pulseRed 5s ease-in-out 0s infinite' : 'none'
              "
            >
              In Range To Be Overtaken
            </div>
            <div
              id="overtakeRangeText"
              [style.color]="
                inRangeFront ? 'rgb(255,255,255)' : 'rgb(70,70,70)'
              "
              [style.animation]="
                inRangeFront ? 'pulseGreen 5s ease-in-out 0s infinite' : 'none'
              "
            >
              In Range For Overtake
            </div>
          </div>
          <ng-template [ngIf]="overtaking">
            <div id="overtakingIndicator" class="otIndicator">
              Overtaking...
            </div>
          </ng-template>

          <ng-template [ngIf]="beingOvertaken && !overtaking">
            <div id="beingOvertakenIndicator" class="otIndicator">
              Being overtaken...
            </div>
          </ng-template>

          <ng-template [ngIf]="!overtaking && !beingOvertaken">
            <div id="gaps">
              <div id="gapBehind" class="gapTextContainer">
                <div class="gapText">Gap behind:&nbsp;</div>
                {{ gapBehind }}
                <div [style.font-size]="'0.8rem'">s</div>
              </div>
              <div id="gapFront" class="gapTextContainer">
                <div class="gapText">Gap ahead:&nbsp;</div>
                {{ gapFront }}
                <div [style.font-size]="'0.8rem'">s</div>
              </div>
            </div>
          </ng-template>
          <!-- <div id="specialOvertakeRow">
            <div id="allowOvertakeContainer">
              <mat-checkbox
                [(ngModel)]="allowOvertake"
                (change)="setAllowOvertake()"
                class="jaguarFont"
              >
                <div class="specialOvertakeLongText">
                  Allow behind to overtake
                </div>
                <div class="specialOvertakeShortText">Allow overtake</div>
              </mat-checkbox>
            </div>
            <div id="disableOvertakingContainer">
              <mat-checkbox
                [(ngModel)]="overtakingDisabled"
                (change)="setOvertakingDisabled()"
                class="jaguarFont"
              >
                <div class="specialOvertakeLongText">
                  Do not attempt to overtake
                </div>
                <div class="specialOvertakeShortText">Do not overtake</div>
              </mat-checkbox>
            </div>
          </div> -->
          <div class="defendingRow">
            <div id="defendRow">
              <div class="defendRowSection">
                <h3 class="stage-1-width-text">Actively Defend</h3>
                <mat-slide-toggle
                  [(ngModel)]="defending"
                  (change)="setDefending()"
                  matTooltip="If set, driver will spend energy to decrease overtaking odds."
                ></mat-slide-toggle>
              </div>
              <div class="defendRowSection">
                <!-- <h3 class="stage-1-width-text">GO Deploy</h3>
                <mat-slide-toggle
                  disabled
                  [(ngModel)]="deploy"
                  (change)="setDeploy()"
                  matTooltip="If set, car will redistribute energy budgets to focus on overtaking segments."
                ></mat-slide-toggle> -->
              </div>
            </div>
          </div>
        </div>
        <div
          class="jaguarFont driverControlsRow"
          [style.visibility]="settingSCPace ? 'visible' : 'hidden'"
          id="scPaceRow"
        >
          Safety car leader pace
          <div id="scPaceSliderRow">
            -
            <mat-slider
              step="1"
              [min]="0"
              [max]="100"
              [(ngModel)]="scPace"
              (change)="setSCPace()"
              style="width: 65%"
            ></mat-slider>
            +
          </div>
        </div>
      </div>
    </div>

    <div id="infoBox">
      <div
        id="pittingScreen"
        [style.visibility]="pitting ? 'visible' : 'hidden'"
      >
        <div id="pittingText" class="jaguarFont">In the pit lane...</div>
      </div>
      <div id="energiesTable" [style.display]="trueDriver ? 'none' : 'inline'">
        <app-energies-table> </app-energies-table>
      </div>

      <div
        id="rcPanel"
        class="infoBoxPanel jaguarFont stage-1-width-text"
        [style.visibility]="trueDriver ? 'hidden' : 'visible'"
      >
        <div id="rcTitle">Race Control Messages</div>
        <div id="rcTitleShort">Race Control</div>
        <div id="rcMessages">
          <div
            *ngFor="let rcMsg of rc.raceControlMessages"
            class="rcRow jaguarFont"
          >
            <div class="rcMsgDate">
              {{ rcMsg.dt.toLocaleTimeString("en-gb") }}
            </div>
            <div class="rcMsgText">
              {{ rcMsg.text }}
            </div>
          </div>
        </div>
      </div>

      <div
        id="interventionPanel"
        class="infoBoxPanel jaguarFont"
        [style.visibility]="
          status.status.interventionTime > 0 && !trueDriver
            ? 'visible'
            : 'hidden'
        "
      >
        <div id="interventionLabel">Total Intervention Time:</div>

        <div
          id="interventionTime"
          [style.color]="
            status.status.currentFlag == 'FULL_YELLOW' ||
            status.status.currentFlag == 'SAFETY_CAR'
              ? 'yellow'
              : 'white'
          "
        >
          {{ status.status.interventionTime }}
        </div>
        <div id="interventionPerLapLabel">
          Time per extra lap: {{ status.status.interventionPerLap }}
        </div>
      </div>

      <div id="echoPanel" class="infoBoxPanel jaguarFont">
        <div id="echoTitleRow">
          <div id="echoTitle" class="stage-1-width-text">Send To Echo</div>
          <div
            id="echoCheck"
            [class]="echoCheck ? 'echoCheckShown' : 'echoCheckHidden'"
          >
            <mat-icon>check</mat-icon>
          </div>
        </div>

        <textarea
          class="textInput"
          id="echoMessageBox"
          (keyup)="echoKeyUp($event)"
          (keydown.enter)="onKeydown($event)"
          placeholder="SHIFT+ENT: Inserts 'ENG: ' prefix"
          [style.border]="echoTextBoxBorder"
        ></textarea>
      </div>

      <div id="gapPanel" class="infoBoxPanel jaguarFont">
        <div id="gapTitle" [ngStyle]="{ opacity: pitting ? '0.2' : '1' }">
          Gaps Around (s)
        </div>
        <div id="gapContainer" [ngStyle]="{ opacity: pitting ? '0.2' : '1' }">
          <hr id="gapLine" />
          <div
            class="gapDot"
            id="centralGapDot"
            [style.background-color]="
              colours.getColourByDriverNum(selectedDriver)
            "
          >
            <div class="gapDotNum" style="top: -1rem">
              {{ colours.getDriverNameByNum(selectedDriver) }}
            </div>
          </div>
          <div
            *ngFor="let gapObj of gaps"
            class="gapDot"
            [style.background-color]="colours.getColourByDriverNum(gapObj.num)"
            [style.left]="'calc(' + gapObj.fractionalGap * 100 + '% - 8px)'"
          >
            <div class="gapDotNum">
              {{ colours.getDriverNameByNum(gapObj.num) }}
            </div>
          </div>
          <div
            *ngFor="let marker of gapMarkers"
            class="gapMarker"
            [style.background-color]="'white'"
            [style.left]="((marker + 3) / (gapRange * 2)) * 100 + '%'"
          >
            <div class="gapMarkerText">{{ marker }}</div>
          </div>
        </div>
        <!-- <app-race-plot [activeDriver]="selectedDriver"> </app-race-plot> -->
      </div>

      <div id="progressPanel" class="infoBoxPanel jaguarFont">
        <div id="progressText">Lap Progress</div>
        <div id="progressAttackRow">
          <div
            id="progressAttack"
            class="progressMarker"
            [style.left]="attackZoneTransformString"
            matTooltip="Attack Zone"
          ></div>
          <div
            *ngFor="let otString of overtakePositionStrings"
            class="progressOT progressMarker"
            [style.left]="otString"
            matTooltip="Overtaking Point"
          ></div>
          <div
            *ngFor="let preOTString of preOvertakePositionStrings"
            class="progressPreOT progressMarker"
            [style.left]="preOTString.leftCSS"
            [style.width]="preOTString.widthCSS"
            [style.border-radius]="
              preOTString.leftCSS == '0%' ? '0 0 0 0' : '5px 0 0 5px'
            "
            [style.background-color]="otSegmentColours[preOTString.leftCSS]"
            matTooltip="Overtaking Segment"
          ></div>
          <div
            *ngFor="let ghostString of ghostSegmentPositionStrings"
            class="progressGhost progressMarker"
            [style.left]="ghostString.leftCSS"
            [style.width]="ghostString.widthCSS"
            [style.border-radius]="
              ghostString.leftCSS == '0%' ? '0 5px 5px 0' : '5px 5px 5px 5px'
            "
            [style.background-color]="ghostSegmentColours[ghostString.leftCSS]"
          ></div>
          <!-- <div class="progressPreOT"></div> -->
        </div>
        <div id="progressBarRow">
          <div id="progressBarContainer">
            <mat-progress-bar [value]="position * 100"> </mat-progress-bar>
          </div>
        </div>
      </div>
      <div id="raceStatusPanel" class="infoBoxPanel">
        <div class="jaguarFont energySocDisplay">
          <div>Energy: {{ (energy ? energy : 0) | number : "1.2-2" }} kWh</div>
          <div>SOC: {{ (soc ? soc : 0) | number : "1.1-1" }}%</div>
        </div>
        <!-- <div class="jaguarFont energyGrid">
          <div class="targetEnergyText">Energy:</div>
          <div
            class="energyRemainingValue dashboardValues"
            [ngClass]="{ targetValueBolded: animateTargetEnergy }"
          >
            <div class="targetEnergyNum">
              {{ energy ? (energy | number : "1.2-2") : "0.00" }}
            </div>
            <div class="targetEnergyUnit">kWh</div>
          </div>
        </div> -->
        <div class="jaguarFont dashboardRow">
          <div class="leftDash">
            <div class="targetEnergyText stage-1-width-text stage-2-hidden">
              Target Energy:
            </div>
            <div class="targetEnergyText stage-1-width-text stage-2-shown">
              Target:
            </div>
            <div
              class="targetEnergyValue dashboardValues"
              [ngClass]="{ targetValueBolded: animateTargetEnergy }"
              matTooltip="Energy Remaining / (Laps Remaining + Lap Offset)"
              matTooltipPosition="after"
            >
              <div class="targetEnergyNum">
                {{ targetEnergy ? (targetEnergy | number : "1.2-2") : "0.00" }}
              </div>
              <div class="targetEnergyUnit">kWh</div>
            </div>
          </div>
          <div class="rightDash">
            <div class="targetEnergyText stage-1-width-text">Predicted:</div>
            <div class="targetEnergyValue dashboardValues">
              <div
                class="targetEnergyNum"
                [style.color]="
                  predictedConsumption >= targetEnergy + 0.01
                    ? 'rgb(255,90,90)'
                    : predictedConsumption <= targetEnergy - 0.01
                    ? 'rgb(90,255,90)'
                    : 'rgb(255,255,255)'
                "
              >
                {{
                  predictedConsumption
                    ? (predictedConsumption | number : "1.2-2")
                    : "0.00"
                }}
              </div>
              <div class="rightSideEnergyUnit">kWh</div>
            </div>
          </div>
        </div>
        <div class="jaguarFont dashboardRow">
          <div class="leftDash">
            <div class="targetEnergyText stage-1-width-text">Last Lap:</div>
            <div
              class="targetEnergyValue dashboardValues"
              [ngClass]="{ targetValueBolded: animateTargetEnergy }"
            >
              <div class="targetEnergyNum">
                {{
                  prevLapEnergyConsumed
                    ? (prevLapEnergyConsumed | number : "1.2-2")
                    : "0.00"
                }}
              </div>
              <div class="targetEnergyUnit">kWh</div>
            </div>
          </div>
          <div class="rightDash">
            <div class="targetEnergyText stage-1-width-text stage-2-hidden">
              Target Delta:
            </div>
            <div class="targetEnergyText stage-1-width-text stage-2-shown">
              Delta:
            </div>
            <div
              class="targetEnergyValue dashboardValues"
              [ngClass]="{ targetValueBolded: animateTargetEnergy }"
            >
              <div
                class="targetEnergyNum"
                [style.color]="
                  prevLapEnergyTargetDelta >= 0.005
                    ? 'rgb(255,90,90)'
                    : prevLapEnergyTargetDelta <= -0.005
                    ? 'rgb(90,255,90)'
                    : 'rgb(255,255,255)'
                "
              >
                {{
                  prevLapEnergyTargetDelta
                    ? (prevLapEnergyTargetDelta | number : "1.2-2")
                    : "0.00"
                }}
              </div>
              <div class="rightSideEnergyUnit">kWh</div>
            </div>
          </div>
        </div>
        <div id="bottomBoxesRow">
          <div
            class="bottomBox codeBox"
            id="greenCodeBox"
            [ngClass]="{ animationClass: animateGreen }"
          >
            {{ greenCode.toUpperCase() }}
          </div>
          <div
            id="status"
            class="bottomBox jaguarFont"
            [style.background-color]="flagColour"
            [style.color]="'black'"
          >
            <!-- <div id="lapStatus">Lap {{ currentLap }} / {{ finalLaps }}</div> -->
            <div id="lapsRemText" class="stage-1-width-text stage-2-hidden">
              Laps Remaining
            </div>
            <div id="lapsRemText" class="stage-2-shown stage-1-width-text">
              Laps Rem.
            </div>
            <div id="lapsRemVal">
              {{ finalLaps + lapOffset - currentLap + 1 }}
            </div>
          </div>
          <div
            class="bottomBox codeBox"
            id="orangeCodeBox"
            [ngClass]="{ animationClass: animateOrange }"
          >
            {{ orangeCode.toUpperCase() }}
          </div>
        </div>
      </div>
      <div
        id="tvStatusPanel"
        class="infoBoxPanel"
        [style.visibility]="trueDriver ? 'hidden' : 'visible'"
      >
        <app-session-status></app-session-status>
      </div>
    </div>
  </div>
</app-main-nav>

<div id="crashed" [style.visibility]="crashed ? 'visible' : 'hidden'">
  <div id="crashedText"><h1>You have crashed :(</h1></div>
</div>
